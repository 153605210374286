body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background: #565252;
}

hr{
  margin: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.fontFamily {
  font-family: monospace;
  font-size: 17px;
}
.userInputSection {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  background: #FFFFFF;
  min-height: 200px;
  margin-top: 1.3rem;
  border: 2px solid;
  border-radius: 10px;
  .validationSection{
    padding: 1.8rem;
    background-color: lightcyan;
    .spreadsheetInput{
      label {
        left: 0 !important;
      }
    }
    .col{
      padding: unset !important;
    }
    .validationOption{
      .dropdown-trigger{
        
        width: 30%;
        border: 1px solid black;
        border-radius: 4px;
      }
      .validationOptionDropdown{
        top: auto !important;
        left: auto !important;
      }
      i{
        vertical-align: middle !important;
        font-size: 2rem !important;
        color: green;
      }
    }
    .travelPeriodInput{
      float: right;
    }
    .continueValidation{
      margin-top: 3rem;
      button{
        width: 100%;
      }
    }
  }
  .triggerResponseData{
    div{
      background: antiquewhite;
      padding: 2rem;
    }
  }
}

.reports{
  margin-top: 1rem;
  background-color: #FFFFFF;
  margin-bottom: 5rem;
  max-height: 500px;
  overflow: scroll;
  border: 2px solid;
  border-radius: 10px;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  .refreshButton{
    margin: 2rem;
    a{
      display: flex;
      background: transparent;
      border-radius: 4px;
      border: 1px solid black;
      color: black;
    }
  }
  .reportHeader{
    top: 1rem;
  }
  .viewReports{
    background-color: transparent;
    color: black;
    border: 1px solid grey;
    border-radius: 4px;
  }
}
.checkPipelineStatus{
  margin-top: 1rem;
  background-color: #FFFFFF;
  border: 2px solid;
  border-radius: 10px;
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
  .row{
    background-color: lightcyan;
  }
  .jobStatusSection{
    display: flex;
  }
  .jobStatus{
    background-color: white;
    min-height: 100px;
    div{
      min-height: 100px;
      padding: 1rem;
      background-color: antiquewhite;
    }
  }
}

.page-footer{
  background-color: #FFFFFF;
  position: relative;
  padding-top: 0;
  width: 100%;
  bottom: 0;
  .row{
    margin-bottom: 0;
    .right p{
      float: right;
    }
  }
}

.title{
    vertical-align: top;
    margin-left: 10px;
    font-family: "Proxima Nova",Arial,sans-serif;
    color:#212121;
    font-size: 28px;
}

table{
  background-color: lightcyan;
}

.googleAuthenticate{
  width: 100%;
  position: fixed;
  top: 15%;
  left: 30%;
  z-index: 1999;
  .card-panel{
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.7); /* Black w/opacity/see-through */
    border-radius: 10px;
    border: 3px solid black;
    p{
      border-radius: 10px;
    }
  }
}
.googleAuthenticate .card-panel button{
  margin-left: 34%;
  padding: 15px !important;
  border-radius: 10px !important;
}


.googleAuthenticate .card-panel button svg{
  transform: scale(2, 2)
}

.googleAuthenticate .card-panel button > span{
  font-size: large;
  font-family: monospace;
}

.enableBlur{
  filter: blur(3px);
  pointer-events: none;
}

.disableBlur {
  filter: blur(0);
}

.viewReportButton{
  background-color: transparent;
  border: 1px solid black;
  border-radius: 4px;
  color: black;
  &:hover{
    color: white;
  }
}

.userInfoBoxLabel {
  padding: 10px 13px;
  border-radius: 50%;
  background-image: linear-gradient(45deg,#ace143,#219393);
}

.textCenter {
  text-align: center;
}
.whiteText {
  color: #fff;
}
.latoBlack {
  font-weight: 900;
}

.font10 {
  font-size: 10px;
  line-height: 10px;
}
.appendRight10 {
  margin-right: 2px;
}

label.selectEnvLabel {
  margin-left: -10px;
  top: -22px;
}
.input-field > label.travelPeriod {
  top: 4px;
  font-size: 3rm;
  left: 0px;

}
// .input-field>label {
//   top: -10px;
// }
/* media query */
@media only screen and (max-width: 650px){
  .userInputSection .validationSection .validationOption .dropdown-trigger {
        width: 100%;
        border: 1px solid black;
        border-radius: 4px;
  }

  .select-wrapper input.select-dropdown {
    font-size: 13px;
  }

  .select-wrapper .caret {
    right: -10px;
  }
}
